/*=============================================>>>>>
= Base Styles =
===============================================>>>>>*/
/*=============================================>>>>>
= Breakpoints =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Up =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Down =
===============================================>>>>>*/
/*=============================================>>>>>
= Media Between =
===============================================>>>>>*/
/*=============================================>>>>>
= Body font colour =
===============================================>>>>>*/
/*=============================================>>>>>
= Brand colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Additional =
===============================================>>>>>*/
/*=============================================>>>>>
= Black/white =
===============================================>>>>>*/
/*=============================================>>>>>
= User noticiation colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Social colours =
===============================================>>>>>*/
/*=============================================>>>>>
= Placeholder =
===============================================>>>>>*/
/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
/*=============================================>>>>>
= Ellipsis =
===============================================>>>>>*/
/* mixin for multiline */
/*=============================================>>>>>
= Position =
===============================================>>>>>*/
/*=============================================>>>>>
= Clearfix =
===============================================>>>>>*/
/*=============================================>>>>>
= Typography Mixins and Variables =
===============================================>>>>>*/
nav {
  width: 100%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 0 40px !important;
  position: relative;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 70px;
  z-index: 1; }
  @media (max-width: 768px) {
    nav {
      padding: 0 10px !important; } }
  nav .nav-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 10px; }
    @media (max-width: 499px) {
      nav .nav-left {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px; } }
    nav .nav-left .navbar-options-icon {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      nav .nav-left .navbar-options-icon:hover {
        cursor: pointer; }
      nav .nav-left .navbar-options-icon:before {
        -webkit-transition: -webkit-transform 0.3s;
        transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s; }
      nav .nav-left .navbar-options-icon.active:before {
        -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg); }
      nav .nav-left .navbar-options-icon .navbar-options-dropdown {
        padding: 5px 15px 5px 15px;
        position: absolute;
        top: 45px;
        left: -80px;
        border: 1px solid rgba(0, 0, 0, .1);
        -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, .1);
                box-shadow: 0 6px 8px 0 rgba(0, 0, 0, .1);
        border-radius: 5px;
        z-index: 2; }
        nav .nav-left .navbar-options-icon .navbar-options-dropdown.inactive {
          display: none; }
        nav .nav-left .navbar-options-icon .navbar-options-dropdown a {
          display: block;
          text-decoration: none;
          color: #ffffff;
          white-space: nowrap; }
  nav .navbar-menu {
    width: 60%; }
    nav .navbar-menu .navbar-menu-items {
      margin: 0;
      padding: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end; }
    nav .navbar-menu .navbar-menu-items > .navbar-menu-item {
      list-style: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: relative;
      padding: 0 13px;
      font-size: 13px;
      cursor: pointer; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item > a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        text-decoration: none; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item .icon:before {
        width: 25px;
        height: 25px;
        display: block;
        background-color: #ffffff; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item .icon-smata-building-white:before {
        width: 27.5px; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item .icon-dashboard-white:before {
        width: 34px; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item .icon-video-camera-white:before {
        width: 27.9px; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item .icon-contact-white:before {
        width: 29px; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item .notification-item-header .icon:before {
        width: 18px;
        height: 18px;
        display: block;
        background-color: #ffffff; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item span.icon-text {
        color: #ffffff;
        font-size: 14px;
        display: none;
        margin-left: 10px; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item .navbar-options-icon {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-left: 10px; }
        nav .navbar-menu .navbar-menu-items > .navbar-menu-item .navbar-options-icon:hover {
          cursor: pointer; }
        nav .navbar-menu .navbar-menu-items > .navbar-menu-item .navbar-options-icon:before {
          -webkit-transition: -webkit-transform 0.3s;
          transition: -webkit-transform 0.3s;
          transition: transform 0.3s;
          transition: transform 0.3s, -webkit-transform 0.3s;
          height: 7px;
          width: 10px; }
        nav .navbar-menu .navbar-menu-items > .navbar-menu-item .navbar-options-icon.active:before {
          -webkit-transform: rotate(-180deg);
                  transform: rotate(-180deg); }
        nav .navbar-menu .navbar-menu-items > .navbar-menu-item .navbar-options-icon .navbar-options-dropdown {
          padding: 5px 15px 5px 15px;
          position: absolute;
          top: 40px;
          right: 0;
          border: 1px solid rgba(0, 0, 0, .1);
          -webkit-box-shadow: 0 6px 8px 0 rgba(0, 0, 0, .1);
                  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, .1);
          border-radius: 5px; }
          nav .navbar-menu .navbar-menu-items > .navbar-menu-item .navbar-options-icon .navbar-options-dropdown.inactive {
            display: none; }
          nav .navbar-menu .navbar-menu-items > .navbar-menu-item .navbar-options-icon .navbar-options-dropdown a {
            display: block;
            text-decoration: none;
            color: #ffffff;
            white-space: nowrap; }
        nav .navbar-menu .navbar-menu-items > .navbar-menu-item .navbar-options-icon .navbar-options-dropdown.navbar-user-options-dropdown {
          left: -125px; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item .message-indicator-wrapper {
        position: relative; }
        nav .navbar-menu .navbar-menu-items > .navbar-menu-item .message-indicator-wrapper .unread-messages-indicator {
          position: absolute;
          top: -18px;
          right: -8px;
          background: #4FCBB2;
          width: 8px;
          height: 8px;
          border-radius: 4px; }
  nav .navbar-menu-logout .navbar-menu-items > .navbar-menu-item span.icon-text {
    display: block; }
  nav .navbar-menu-logout a {
    color: #ffffff; }
  nav .hamburger-menu-container {
    position: absolute;
    right: 16px;
    right: 1rem;
    display: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  nav .hamburger-menu {
    width: 40px;
    width: 2.5rem;
    height: 40px;
    height: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  nav .hamburger-menu div {
    width: 25.6px;
    width: 1.6rem;
    height: 3px;
    border-radius: 3px;
    background-color: #ffffff;
    position: relative;
    -webkit-transition: .5s;
    transition: .5s; }
  nav .hamburger-menu div:before,
  nav .hamburger-menu div:after {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: #ffffff;
    border-radius: 3px; }
  nav .hamburger-menu div:before {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px); }
  nav .hamburger-menu div:after {
    -webkit-transform: translateY(7px);
            transform: translateY(7px); }
  nav #hamburger_menu_toggler {
    position: absolute;
    top: 50%;
    right: 16px;
    right: 1rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 40px;
    width: 2.5rem;
    height: 40px;
    height: 2.5rem;
    cursor: pointer;
    opacity: 0;
    display: none; }
  nav #hamburger_menu_toggler:checked ~ .hamburger-menu-container .hamburger-menu div {
    background-color: transparent !important; }
  nav #hamburger_menu_toggler:checked ~ .hamburger-menu-container .hamburger-menu div:before {
    -webkit-transform: translateY(0) rotate(-45deg);
            transform: translateY(0) rotate(-45deg); }
  nav #hamburger_menu_toggler:checked ~ .hamburger-menu-container .hamburger-menu div:after {
    -webkit-transform: translateY(0) rotate(45deg);
            transform: translateY(0) rotate(45deg); }
  nav input#hamburger_menu_toggler:checked ~ .navbar-menu {
    display: block; }
  @media (max-width: 1024px) {
    nav .navbar-menu {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 100;
      display: none;
      width: 100% !important;
      height: 100%;
      background: rgba(0, 0, 0, .5);
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }
      nav .navbar-menu .navbar-menu-items {
        position: absolute;
        right: 0;
        top: 0;
        background: #000000;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
        width: 240px;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      nav .navbar-menu span.icon-text {
        display: block !important; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item {
        padding: 15px;
        z-index: 1; }
      nav .navbar-menu .navbar-menu-items > .navbar-menu-item:last-child {
        padding: 70px 15px 15px 15px; }
    nav nav .navbar-menu .navbar-menu-items > .navbar-menu-item .navbar-options-icon .navbar-options-dropdown {
      top: 30px; }
    nav .hamburger-menu-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      z-index: 101; }
    nav #hamburger_menu_toggler {
      display: block;
      z-index: 102; } }

.locations-dropdown-button, .services-dropdown-button {
  font-size: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: normal;
  padding: 3px 4px;
  text-decoration: none;
  border-radius: 4px;
  background: #000000;
  color: #ffffff;
  border: 1px solid #ffffff; }
  .locations-dropdown-button:hover, .services-dropdown-button:hover {
    background: #000000;
    color: #ffffff;
    border-color: #ffffff; }

.locations-and-services-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px; }

.logo-and-organisation-dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px; }
